export const faqQuestions = [
  {
    title: "What are you rental rates and options?",
    description:
      "We offer two rental options: full service and self service. Our full service option includes delivery, pick up, dumping, and cleaning of the trailer. Our self service option requires you to pick up, fill, dump, and return the trailer yourself. For our full service option, we charge a $20 delivery fee.",
  },
  {
    title: "Do I need a deposit to rent a trailer?",
    description:
      "No, we do not require a deposit to rent a trailer. However, you will need to pay for your rental in full at the time of booking.",
  },
  {
    title: "What is your cancellation policy?",
    description:
      "We offer a full refund for cancellations made at least 24 hours before your rental period begins for single full day rentals. Cancellations made less than 24 hours before your rental period will be charged 50% of the rental fee. For multi-day rentals, cancellations made at least 48 hours before your rental period begins will receive a full refund. Cancellations made less than 48 hours before your rental period will be the first day of the rental period.",
  },
  {
    title: "What are the requirements for towing a trailer?",
    description:
      "To tow one of our trailers, you will need a vehicle with a towing capacity of at least 10,000 lbs and a 2 5/16 in. ball hitch. You will also need a valid driver's license.",
  },
];
