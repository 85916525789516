import * as React from "react";
import { SectionWrapper } from "~src/components";

interface Fee {
  name: string;
  amount: number;
  description: string;
}

export const AdditionalFeesSection: React.FC = () => {
  const additionalFees: Fee[] = [
    {
      name: "Security Deposit",
      amount: 500,
      description: "Refundable deposit to cover potential damages",
    },
    {
      name: "Late Return Fee (Self-Service Only)",
      amount: 50,
      description:
        "Per day charge for returning the trailer after the agreed-upon date",
    },
    {
      name: "Insurance Coverage",
      amount: 10,
      description: "Per day charge for insurance coverage on the trailer",
    },
    {
      name: "Cleaning Fee",
      amount: 50,
      description: "Non-refundable fee for cleaning the trailer",
    },
  ];

  return (
    <SectionWrapper>
      <div className="flex-auto text-left">
        <h2 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          Additional Fees
        </h2>
        <p className="my-6 max-w-3xl text-left text-lg leading-8 text-gray-600">
          We offer a variety of additional fees to cover potential damages, late
          returns, and insurance coverage. Please review the following:
        </p>
      </div>
      <div className="space-y-6">
        {additionalFees.map((fee) => (
          <div
            key={fee.name}
            className="bg-slate-900 shadow overflow-hidden sm:rounded-lg"
          >
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-white flex justify-between">
                {fee.name}
                <span className="text-white text-2xl">${fee.amount}</span>
              </h3>
              <p className="mt-1 text-sm text-gray-300">{fee.description}</p>
            </div>
          </div>
        ))}
      </div>
    </SectionWrapper>
  );
};
