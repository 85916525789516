import * as React from "react";
import { TrailerCalendar } from "../components";
import { dumpTrailer } from "../../../data/dumpTrailer";

export const TrailerBookingView = () => {
  return (
    <div className="flex p-4">

    </div>
  );
};
