export const utahCountyCityOptions = [
  { value: "Alpine", label: "Alpine" },
  { value: "American Fork", label: "American Fork" },
  { value: "Cedar Hills", label: "Cedar Hills" },
  { value: "Eagle Mountain", label: "Eagle Mountain" },
  { value: "Elk Ridge", label: "Elk Ridge" },
  { value: "Highland", label: "Highland" },
  { value: "Lehi", label: "Lehi" },
  { value: "Lindon", label: "Lindon" },
  { value: "Mapleton", label: "Mapleton" },
  { value: "Orem", label: "Orem" },
  { value: "Payson", label: "Payson" },
  { value: "Pleasant Grove", label: "Pleasant Grove" },
  { value: "Provo", label: "Provo" },
  { value: "Salem", label: "Salem" },
  { value: "Santaquin", label: "Santaquin" },
  { value: "Saratoga Springs", label: "Saratoga Springs" },
  { value: "Spanish Fork", label: "Spanish Fork" },
  { value: "Springville", label: "Springville" },
  { value: "Woodland Hills", label: "Woodland Hills" },
];

export const dayRentalOptions = [
  { value: "half", label: "Half Day (5 Hours)" },
  { value: "full", label: "Full Day (24 Hours)" },
];

export const bookingSteps = [
  {
    id: "1",
    name: "Select dates",
    href: "#",
    status: "current" as "current",
  },
  {
    id: "2",
    name: "Select Service",
    href: "#",
    status: "upcoming" as "upcoming",
  },
  {
    id: "3",
    name: "Enter Details",
    href: "#",
    status: "upcoming" as "upcoming",
  },
  {
    id: "4",
    name: "Order Preview",
    href: "#",
    status: "upcoming" as "upcoming",
  },
  {
    id: "5",
    name: "Payment",
    href: "#",
    status: "upcoming" as "upcoming",
  },
];
